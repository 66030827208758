import React, { useEffect, useState } from "react"
import { Row, Col, Card, Input, Spinner, Alert } from "reactstrap"
import { connect } from "react-redux"
import { Button, CardBody, CardTitle, CardText } from "reactstrap"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"
import CommonLogic from "components/Common/CommonLogic"
import { Editor } from "react-draft-wysiwyg"

const PaymentPage = props => {
  document.title = "Payment | PostReview"

  const breadcrumbItems = [
    { title: "PostReview", link: "#" },

    { title: "Payment", link: "payment-page" },
  ]
  const [merchantInfo, setMerchantInfo] = useState("")
  const razorpayUrl = `https://pages.razorpay.com/pl_PWEosERrJncNaX/view?email=${merchantInfo.email}&phone=${merchantInfo.mobileno}&GSTNo=${merchantInfo.gstNumber}`

  useEffect(() => {
    props.setBreadcrumbItems("Payment", breadcrumbItems)
    const scriptId = "razorpay-embed-btn-js"
    if (!document.getElementById(scriptId)) {
      const script = document.createElement("script")
      script.defer = true
      script.id = scriptId
      script.src = "https://cdn.razorpay.com/static/embed_btn/bundle.js"
      document.body.appendChild(script)
    } else {
      const rzp = window["__rzp__"]
      if (rzp && rzp.init) rzp.init()
    }
  })
  console.log("merchantInfo", merchantInfo)
  return (
    <React.Fragment>
      <CommonLogic>
        {({ merchantData, loading, error: merchantError }) => {
          if (loading)
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                }}
              >
                <Spinner
                  size="xg"
                  className="me-2"
                  style={{ color: "#2B3A4A" }}
                />
                Loading...
              </div>
            )
          if (merchantError)
            return <Alert color="danger">{merchantError}</Alert>
          if (!merchantData || !merchantData._id)
            return (
              <Alert color="warning">Merchant data is not available.</Alert>
            )

          // Set the merchant ID once it's available
          if (merchantData) {
            setMerchantInfo(merchantData)
          }

          return (
            <Row>
              <Col xs="12">
                <div className="d-flex justify-content-center align-items-center mt-5">
                  <Card
                    className="shadow-lg"
                    style={{ width: "100%", maxWidth: "600px" }}
                  >
                    <div className="card-header text-white text-center bg-primary">
                      <h1 className="display-6">Premium Plan</h1>
                      <p className="lead">Upgrade to unlock full potential</p>
                    </div>
                    <CardBody>
                      <CardTitle tag="h2" className="text-center text-success">
                        Only $49.99/month
                      </CardTitle>
                      <CardText className="text-muted text-center">
                        Enjoy the following benefits:
                      </CardText>
                      <CardText>
                        <ul className="list-unstyled text-center">
                          <li>
                            <i className="bi bi-check-circle-fill text-success"></i>{" "}
                            - Unlimited Access
                          </li>
                          <li>
                            <i className="bi bi-check-circle-fill text-success"></i>
                            - Priority Support
                          </li>
                          <li>
                            <i className="bi bi-check-circle-fill text-success"></i>
                            - Custom Reports
                          </li>
                        </ul>
                      </CardText>
                      <div className="d-flex justify-content-center">
                        <div
                          className="razorpay-embed-btn"
                          data-url={razorpayUrl}
                          data-text="Pay Now"
                          data-color="#528FF0"
                          data-size="large"
                        ></div>
                      </div>
                    </CardBody>
                    <div className="card-footer text-center text-muted">
                      Secure payment powered by Razorpay
                    </div>
                  </Card>
                </div>
              </Col>
            </Row>
          )
        }}
      </CommonLogic>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(PaymentPage)
