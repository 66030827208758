// import PropTypes from "prop-types"
// import React, { useCallback, useEffect, useRef } from "react"

// // //Import Scrollbar
// import SimpleBar from "simplebar-react"

// // MetisMenu
// import MetisMenu from "metismenujs"
// import withRouter from "components/Common/withRouter"
// import { Link } from "react-router-dom"

// //i18n
// import { withTranslation } from "react-i18next"

// const SidebarContent = props => {
//   const ref = useRef()
//   const activateParentDropdown = useCallback(item => {
//     item.classList.add("active")
//     const parent = item.parentElement
//     const parent2El = parent.childNodes[1]

//     if (parent2El && parent2El.id !== "side-menu") {
//       parent2El.classList.add("mm-show")
//     }

//     if (parent) {
//       parent.classList.add("mm-active")
//       const parent2 = parent.parentElement

//       if (parent2) {
//         parent2.classList.add("mm-show") // ul tag

//         const parent3 = parent2.parentElement // li tag

//         if (parent3) {
//           parent3.classList.add("mm-active") // li
//           parent3.childNodes[0].classList.add("mm-active") //a
//           const parent4 = parent3.parentElement // ul
//           if (parent4) {
//             parent4.classList.add("mm-show") // ul
//             const parent5 = parent4.parentElement
//             if (parent5) {
//               parent5.classList.add("mm-show") // li
//               parent5.childNodes[0].classList.add("mm-active") // a tag
//             }
//           }
//         }
//       }
//       scrollElement(item)
//       return false
//     }
//     scrollElement(item)
//     return false
//   }, [])

//   const removeActivation = items => {
//     for (var i = 0; i < items.length; ++i) {
//       var item = items[i]
//       const parent = items[i].parentElement

//       if (item && item.classList.contains("active")) {
//         item.classList.remove("active")
//       }
//       if (parent) {
//         const parent2El =
//           parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
//             ? parent.childNodes[1]
//             : null
//         if (parent2El && parent2El.id !== "side-menu") {
//           parent2El.classList.remove("mm-show")
//         }

//         parent.classList.remove("mm-active")
//         const parent2 = parent.parentElement

//         if (parent2) {
//           parent2.classList.remove("mm-show")

//           const parent3 = parent2.parentElement
//           if (parent3) {
//             parent3.classList.remove("mm-active") // li
//             parent3.childNodes[0].classList.remove("mm-active")

//             const parent4 = parent3.parentElement // ul
//             if (parent4) {
//               parent4.classList.remove("mm-show") // ul
//               const parent5 = parent4.parentElement
//               if (parent5) {
//                 parent5.classList.remove("mm-show") // li
//                 parent5.childNodes[0].classList.remove("mm-active") // a tag
//               }
//             }
//           }
//         }
//       }
//     }
//   }

//   const activeMenu = useCallback(() => {
//     const pathName = process.env.PUBLIC_URL + props.router.location.pathname
//     let matchingMenuItem = null
//     const ul = document.getElementById("side-menu")
//     const items = ul.getElementsByTagName("a")
//     removeActivation(items)

//     for (let i = 0; i < items.length; ++i) {
//       if (pathName === items[i].pathname) {
//         matchingMenuItem = items[i]
//         break
//       }
//     }
//     if (matchingMenuItem) {
//       activateParentDropdown(matchingMenuItem)
//     }
//   }, [props.router.location.pathname, activateParentDropdown])

//   useEffect(() => {
//     ref.current.recalculate()
//   }, [])

//   useEffect(() => {
//     new MetisMenu("#side-menu")
//   }, [])

//   useEffect(() => {
//     window.scrollTo({ top: 0, behavior: "smooth" })
//     activeMenu()
//   }, [activeMenu])

//   function scrollElement(item) {
//     if (item) {
//       const currentPosition = item.offsetTop
//       if (currentPosition > window.innerHeight) {
//         ref.current.getScrollElement().scrollTop = currentPosition - 300
//       }
//     }
//   }

//   return (
//     <React.Fragment>
//       <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
//         <div id="sidebar-menu">
//           <ul className="metismenu list-unstyled" id="side-menu">
//             <li className="menu-title">{/* {props.t("Main")}  */}</li>
//             <li>
//               <Link to="/dashboard" className="waves-effect">
//                 <i className="mdi mdi-view-dashboard"></i>

//                 <span>{props.t("Dashboard")}</span>
//               </Link>
//             </li>

//             {/* <li>
//               <Link to="/#" className="has-arrow waves-effect">
//                 <i className="mdi mdi-buffer"></i>
//                 <span>{props.t("Payment")}</span>
//               </Link>
//               <ul className="sub-menu">
//                 <li>
//                   <Link to="/payment-history">
//                     {props.t("Payment History")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link to="/ui-buttons">
//                     {props.t("Payment method change option")}
//                   </Link>
//                 </li>
//               </ul>
//             </li> */}

//             <li>
//               <Link to="/negative-review-data" className=" waves-effect">
//                 <i className="mdi mdi-billboard"></i>

//                 <span>Negative Review Data</span>
//               </Link>
//             </li>
//             <li>
//               <Link to="/AI-Form" className=" waves-effect">
//                 <i className="mdi mdi-form-select"></i>
//                 <span> AI-Form </span>
//               </Link>
//             </li>
//             <li>
//               <Link to="/merchant-info" className=" waves-effect">
//                 <i className="mdi mdi-account"></i>

//                 <span>Merchant Info </span>
//               </Link>
//             </li>
//             <li>
//               <Link to="/ui-cards" className=" waves-effect">
//                 <i className="mdi mdi-calendar-check"></i>
//                 <span>{props.t("Subscription")}</span>
//               </Link>
//             </li>
//             <li>
//               <Link to="/payment-page" className=" waves-effect">
//                 <i className="mdi mdi-billboard"></i>

//                 <span>Payment</span>
//               </Link>
//             </li>
//             <li>
//               <Link to="/support" className=" waves-effect">
//                 <i className="mdi mdi-billboard"></i>

//                 <span>Support</span>
//               </Link>
//             </li>
//           </ul>
//         </div>
//       </SimpleBar>
//     </React.Fragment>
//   )
// }

// SidebarContent.propTypes = {
//   location: PropTypes.object,
//   t: PropTypes.any,
// }

// export default withRouter(withTranslation()(SidebarContent))
/***************************** */
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef } from "react"

// Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link } from "react-router-dom"

// i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()

  const activateParentDropdown = useCallback(item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") // a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show")
        }

        parent.classList.remove("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove("mm-show")

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove("mm-active") // li
            parent3.childNodes[0].classList.remove("mm-active")

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove("mm-show") // li
                parent5.childNodes[0].classList.remove("mm-active") // a tag
              }
            }
          }
        }
      }
    }
  }

  const activeMenu = useCallback(() => {
    const pathName = process.env.PUBLIC_URL + props.router.location.pathname
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [props.router.location.pathname, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  }, [])

  useEffect(() => {
    new MetisMenu("#side-menu")
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    activeMenu()
  }, [activeMenu])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{/* {props.t("Main")} */}</li>
            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="mdi mdi-view-dashboard"></i>
                <span>{props.t("Dashboard")}</span>
                <small
                  className="d-block d-sm-none"
                  style={{ fontSize: "8px" }}
                >
                  Dashboard
                </small>
              </Link>
            </li>
            <li>
              <Link to="/merchant-info" className="waves-effect">
                <i className="mdi mdi-account"></i>
                <span>Merchant Info</span>
                <small
                  className="d-block d-sm-none"
                  style={{ fontSize: "8px" }}
                >
                  Merchant
                </small>
              </Link>
            </li>
            <li>
              <Link to="/positive-review-data" className="waves-effect">
                {/* <i className="mdi mdi-billboard"></i> */}
                <i className="mdi mdi-thumb-up"></i>
                <span>Positive Review Data</span>
                <small
                  className="d-block d-sm-none"
                  style={{ fontSize: "8px" }}
                >
                  +ve Review Data
                </small>
              </Link>
            </li>

            <li>
              <Link to="/negative-review-data" className="waves-effect">
                {/* <i className="mdi mdi-billboard"></i> */}
                <i className="mdi mdi-thumb-down"></i>
                <span>Negative Review Data</span>
                <small
                  className="d-block d-sm-none"
                  style={{ fontSize: "8px" }}
                >
                  -ve Review Data
                </small>
              </Link>
            </li>
            <li>
              <Link to="/AI-Form" className="waves-effect">
                <i className="mdi mdi-form-select"></i>
                <span>AI-Form</span>
                <small
                  className="d-block d-sm-none"
                  style={{ fontSize: "8px" }}
                >
                  AI Form
                </small>
              </Link>
            </li>

            <li>
              <Link to="/subscription-plans" className="waves-effect">
                <i className="mdi mdi-currency-inr"></i>
                <span>{props.t("Subscription")}</span>
                <small
                  className="d-block d-sm-none"
                  style={{ fontSize: "8px" }}
                >
                  Subscription
                </small>
              </Link>
            </li>
            {/* <li>
              <Link to="/payment-page" className="waves-effect">
                <i className="mdi mdi-billboard"></i>
                <span>Payment</span>
                <small
                  className="d-block d-sm-none"
                  style={{ fontSize: "8px" }}
                >
                  Payment
                </small>
              </Link>
            </li> */}
            <li>
              <Link to="/support" className="waves-effect">
                <i className="mdi mdi-email-outline"></i>

                <span>Support</span>
                <small
                  className="d-block d-sm-none"
                  style={{ fontSize: "8px" }}
                >
                  Support
                </small>
              </Link>
            </li>

            <li>
              <Link to="/faq " className="waves-effect">
                <i className="mdi mdi-comment-question-outline"></i>
                <span>FAQ </span>
                <small
                  className="d-block d-sm-none"
                  style={{ fontSize: "8px" }}
                >
                  FAQ
                </small>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
