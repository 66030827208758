import React, { useState, useEffect } from "react"
import axios from "axios"
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const CommonLogic = ({ children }) => {
  const [merchantData, setMerchantData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const email = localStorage.getItem("email")
  const token = localStorage.getItem("token")

  useEffect(() => {
    const fetchMerchantData = async () => {
      try {
        if (!email) {
          setError("Email is required to fetch merchant data")
          setLoading(false)
          return
        }

        const response = await axios.get(
          `${API_BASE_URL}/merchantsinfos/${email}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          },
        )

        if (response && response.data) {
          console.log("abc", response.data)

          setMerchantData(response.data)
        } else {
          setError("No merchant data found for the given email")
        }
      } catch (err) {
        console.error("Error fetching merchant data:", err)
        setError("Failed to fetch merchant data")
      } finally {
        setLoading(false)
      }
    }

    fetchMerchantData()
  }, [email, token])

  // Render the UI via children or a fallback
  return children({ merchantData, loading, error })
}

export default CommonLogic
