import React from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"

const SubscriptionStatusPage = () => {
  const primaryColor = "#007bff" // Primary color for the border and theme
  const headerColor = "#7A6FBE" // Color for the header text

  return (
    <Container
      fluid
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f4f4f4",
      }}
    >
      <Row className="justify-content-center">
        <Col xs="12" md="6" lg="8">
          <Card>
            <CardBody
              style={{
                textAlign: "center",
                backgroundColor: "#fff",
                padding: "40px",
                borderRadius: "10px",
              }}
            >
              <h1
                style={{
                  color: headerColor,
                  fontSize: "28px",
                  fontWeight: "bold",
                  marginBottom: "20px",
                }}
              >
                Subscription Status
              </h1>
              <p
                style={{
                  color: "#000",
                  fontSize: "16px",
                  lineHeight: "1.6",
                }}
              >
                User doesn't have an active subscription. Please subscribe to
                enjoy premium features.
              </p>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default SubscriptionStatusPage
