// import React, { useEffect, useState } from "react"
// import { Row, Col, Card, Input, Spinner } from "reactstrap"
// import { connect } from "react-redux"
// import { Button, CardBody, CardTitle, CardText } from "reactstrap"
// import { useParams } from "react-router-dom"

// // Pages Components
// import Miniwidget from "./Miniwidget"
// import MonthlyEarnings from "./montly-earnings"
// //Import Action to copy breadcrumb items from local state to redux state
// import { setBreadcrumbItems } from "../../store/actions"

// //Import Email Sidebar
// // import EmailSideBar from "./email-sidebar"
// import { Editor } from "react-draft-wysiwyg"
// import axios from "axios"
// import RecentActivity from "./recent-activity"
// import MerchantInfos from "components/AIReviewPages/MerchantInfos"
// const Dashboard = props => {
//   document.title = "Dashborad | PostReview"

//   const breadcrumbItems = [
//     { title: "PostReview", link: "#" },

//     { title: "Dashborad", link: "/dashboard" },
//   ]
//   // const { businessname } = useParams() // Extracting `businessname` from the URL
//   const [merchantData, setMerchantData] = useState(null) // State to store merchant data
//   const [loading, setLoading] = useState(true) // Loading state
//   const [error, setError] = useState(null) // Error state
//   const [allReviews, setAllReviews] = useState("")
//   const email = localStorage.getItem("email")
//   console.log("businessNameOrEmail", email)
//   const token = localStorage.getItem("token")

//   useEffect(() => {
//     props.setBreadcrumbItems("Dashborad", breadcrumbItems)
//     // Fetch data from the API
//     const fetchMerchantData = async () => {
//       try {
//         if (!email) {
//           setError("email is required")
//           return
//         }

//         const response = await axios.get(
//           `https://api.postaireview.com/merchantsinfos/${email}`,
//           {
//             headers: {
//               Authorization: `Bearer ${token}`, // Pass token in header
//               "Content-Type": "application/json",
//             },
//           },
//         )

//         if (response && response.data) {
//           setMerchantData(response.data) // Set fetched data to state
//         } else {
//           setError("No data found")
//         }
//       } catch (error) {
//         console.error("Error fetching merchant data:", error)
//         setError("Failed to fetch merchant data")
//       } finally {
//         setLoading(false)
//       }
//     }

//     fetchMerchantData()

//     fetchAllReviews()
//   }, [email, setBreadcrumbItems])
//   console.log("allReviews", allReviews)
//   console.log("merchantData", merchantData._id)
//   const fetchAllReviews = async () => {
//     try {
//       if (!merchantData._id) {
//         setError("MerchantInfos is required")
//         return
//       }

//       const response = await axios.get(
//         `https://api.postaireview.com/aireviews/${merchantData._id}`,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`, // Pass token in header
//             "Content-Type": "application/json",
//           },
//         },
//       )

//       if (response && response.data) {
//         setAllReviews(response.data) // Set fetched data to state
//       } else {
//         setError("No reviews found")
//       }
//     } catch (error) {
//       console.error("Error fetching Reviews:", error)
//       setError("Failed to fetch Reviews ")
//     } finally {
//       setLoading(false)
//     }
//   }
// const reports = [
//   {
//     title: "Positive Reviews",
//     iconClass: "thumb-up",
//     total: "125",
//     average: "+15%",
//     badgecolor: "success",
//   },
//   {
//     title: "Negative Reviews",
//     iconClass: "thumb-down",
//     total: "50",
//     average: "-5%",
//     badgecolor: "danger",
//   },
//   {
//     title: "Total QR Code Scans",
//     iconClass: "qrcode-scan",
//     total: "150",
//     average: "+20%",
//     badgecolor: "primary",
//   },
// ]

//   if (loading) {
//     return (
//       <>
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             height: "50vh",
//           }}
//         >
//           <Spinner size="lg" className="me-2" style={{ color: "#2B3A4A" }} />
//           Loading...
//         </div>
//       </>
//     )
//   }

//   if (error) {
//     return <p>{error}</p>
//   }
//   return (
//     <React.Fragment>
//       {/* Mini widgets */}
//       <Miniwidget reports={reports} />
//       <hr></hr>
//       <Row>
//         <Col xl="12">
//           {/* Monthly Earnings */}
//           <MonthlyEarnings merchantData={merchantData} />
//         </Col>
//         {/* <Col xl="8">

//           <RecentActivity />
//         </Col> */}
//       </Row>
//     </React.Fragment>
//   )
// }

// export default connect(null, { setBreadcrumbItems })(Dashboard)

import React, { useEffect, useState } from "react"
import { Row, Col, Card, Spinner } from "reactstrap"
import { connect } from "react-redux"
import Miniwidget from "./Miniwidget"
import MonthlyEarnings from "./montly-earnings"
import { setBreadcrumbItems } from "../../store/actions"
import axios from "axios"

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Dashboard = props => {
  document.title = "Dashboard | PostReview"

  const breadcrumbItems = [
    { title: "PostReview", link: "#" },
    { title: "Dashboard", link: "/dashboard" },
  ]

  const [merchantData, setMerchantData] = useState(null)
  const [allReviewsCount, setAllReviewsCount] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const email = localStorage.getItem("email")
  const token = localStorage.getItem("token")

  useEffect(() => {
    props.setBreadcrumbItems("Dashboard", breadcrumbItems)

    const fetchMerchantData = async () => {
      try {
        if (!email) {
          setError("Email is required.")
          return
        }

        const response = await axios.get(
          `${API_BASE_URL}/merchantsinfos/${email}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          },
        )

        if (response && response.data) {
          setMerchantData(response.data)
        } else {
          setError("No merchant data found.")
        }
      } catch (error) {
        console.error("Error fetching merchant data:", error)
        setError("Failed to fetch merchant data.")
      } finally {
        setLoading(false)
      }
    }

    fetchMerchantData()
  }, [email, token, props])

  useEffect(() => {
    if (merchantData?._id) {
      fetchAllReviewsCount(merchantData._id)
    }
  }, [merchantData])

  const fetchAllReviewsCount = async () => {
    if (!merchantData._id) return
    try {
      const response = await axios.get(
        `${API_BASE_URL}/reviews/count/${merchantData._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Pass token in header
            "Content-Type": "application/json",
          },
        },
      )

      if (response && response.data) {
        setAllReviewsCount(response.data) // Set fetched data to state
      } else {
        setError("No data found")
      }
    } catch (error) {
      console.error("Error fetching reviews count:", error)
      setError("Failed to fetch reviews count")
    } finally {
      setLoading(false)
    }
  }

  const reports = [
    {
      title: "Positive Reviews",
      iconClass: "thumb-up",
      total: allReviewsCount.positiveReviews,
      average: "+15%",
      badgecolor: "success",
    },
    {
      title: "Negative Reviews",
      iconClass: "thumb-down",
      total: allReviewsCount.negativeReviews,
      average: "-5%",
      badgecolor: "danger",
    },
    {
      title: "Total QR Code Scans",
      iconClass: "qrcode-scan",
      total: allReviewsCount.positiveReviews + allReviewsCount.negativeReviews,
      average: "+20%",
      badgecolor: "primary",
    },
  ]
  console.log("allReviewsCount", allReviewsCount)
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <Spinner size="lg" className="me-2" style={{ color: "#2B3A4A" }} />
        Loading...
      </div>
    )
  }

  if (error) {
    return <p>{error}</p>
  }

  return (
    <React.Fragment>
      {/* Mini widgets */}
      <Miniwidget reports={reports} />
      <hr />
      <Row>
        <Col xl="12">
          {/* Monthly Earnings */}
          <MonthlyEarnings merchantData={merchantData} />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Dashboard)
