//allRoutes.js
import React from "react"
import { Navigate } from "react-router-dom"

// Profile

import NegativeReviewForm from "components/AIReviewPages/NegativeReviewForm"
import NegativeReviewData from "components/AIReviewPages/NegativeReviewData"

import AIReviewCreation from "components/AIReviewPages/AIReviewCreation"
// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import SetPassword from "pages/Authentication/SetPassword"
import ResetPasswordPage from "pages/Authentication/ResetPasswordPage"
import Dashboard from "../pages/Dashboard/index"

import PaymentPage from "components/AIReviewPages/PaymentPage"
import Pages404 from "../pages/Extra Pages/pages-404"
import Pages500 from "../pages/Extra Pages/pages-500"
import AIForm from "components/AIReviewPages/AIForm"
import MerchantInfos from "components/AIReviewPages/MerchantInfos"
import ProfileMenu from "components/CommonForBoth/TopbarDropdown/ProfileMenu"
import SupportPage from "components/AIReviewPages/SupportPage"
import SubscriptionPlans from "components/AIReviewPages/SubscriptionPlans"
import PositiveReviewData from "components/AIReviewPages/PositiveReviewData"
import ConfirmMsg from "components/AIReviewPages/ConfirmMsg"
import FAQAccordion from "components/AIReviewPages/FAQAccordion"
import SubscriptionStatusPage from "components/AIReviewPages/SubscriptionStatusPage"
const userRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/profile", component: <ProfileMenu /> },
  { path: "/negative-review-form", component: <NegativeReviewForm /> },
  { path: "/positive-review-data", component: <PositiveReviewData /> },
  { path: "/negative-review-data", component: <NegativeReviewData /> },
  { path: "/AI-Form", component: <AIForm /> },
  { path: "/merchant-info", component: <MerchantInfos /> },

  { path: "/payment-page", component: <PaymentPage /> },
  { path: "/faq", component: <FAQAccordion /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "/support", component: <SupportPage /> },
  { path: "/subscription-plans", component: <SubscriptionPlans /> },
]

const authRoutes = [
  { path: "/negative-review-form", component: <NegativeReviewForm /> },
  { path: "/confirm-msg", component: <ConfirmMsg /> },
  { path: "/:businessname", component: <AIReviewCreation /> },
  { path: "/subscription-status", component: <SubscriptionStatusPage /> },

  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/set-password", component: <SetPassword /> },
  { path: "/reset-password", component: <ResetPasswordPage /> },
  { path: "/register", component: <Register /> },

  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },
]

export { userRoutes, authRoutes }
