import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
  Spinner,
  Alert,
} from "reactstrap"
import SimpleBar from "simplebar-react"
import CommonLogic from "components/Common/CommonLogic"
import { withTranslation } from "react-i18next"

const ShareDropdown = props => {
  const [menu, setMenu] = useState(false)
  const [businessname, setBusinessname] = useState(null)

  //  WhatsApp sharing link
  const whatsappShareLink = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    "Please take a moment and review us on : " +
      `https://test.postaireview.com/${businessname}`,
  )}`

  return (
    <CommonLogic>
      {({ merchantData, loading, error: merchantError }) => {
        if (loading)
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <p size="xg" className="me-2" style={{ color: "#2B3A4A" }}>
                loading..
              </p>
            </div>
          )
        if (merchantError) return <Alert color="danger">{merchantError}</Alert>
        if (!merchantData || !merchantData.businessname)
          return <Alert color="warning">Merchant data is not available.</Alert>

        // Set the merchant ID once it's available
        if (merchantData.businessname !== businessname) {
          setBusinessname(merchantData.businessname)
        }

        return (
          <div>
            <Dropdown
              isOpen={menu}
              toggle={() => setMenu(!menu)}
              className="dropdown d-inline-block ms-1"
              tag="li"
            >
              <DropdownToggle
                className="btn header-item noti-icon waves-effect"
                tag="button"
                id="page-header-share-dropdown"
              >
                <i className="mdi mdi-share-variant"></i> {/* Share icon */}
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
                <div className="p-3">
                  <Row className="align-items-center">
                    <Col>
                      <h5 className="m-0"> {props.t("Share")} </h5>
                    </Col>
                  </Row>
                </div>

                <SimpleBar style={{ height: "230px" }}>
                  {/* WhatsApp Share */}
                  <Link
                    to="#"
                    className="text-reset notification-item"
                    onClick={() => window.open(whatsappShareLink, "_blank")}
                  >
                    <div className="d-flex align-items-center">
                      <i
                        className="mdi mdi-whatsapp me-3"
                        style={{ fontSize: "36px", color: "#25D366" }}
                      ></i>
                      <div className="flex-grow-1">
                        <h6 className="mb-1">Share via WhatsApp</h6>
                      </div>
                    </div>
                  </Link>
                </SimpleBar>
              </DropdownMenu>
            </Dropdown>
          </div>
        )
      }}
    </CommonLogic>
  )
}

ShareDropdown.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(ShareDropdown)
