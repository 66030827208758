import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  FormFeedback,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap"
import logoDark from "../../assets/images/Logo.png"
import logoLight from "../../assets/images/Logo.png"
import { useFormik } from "formik"
import * as Yup from "yup"
import axios from "axios"

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const ForgetPasswordPage = () => {
  document.title = "Forget Password | PostReview"

  const [forgetError, setForgetError] = React.useState(null)
  const [forgetSuccessMsg, setForgetSuccessMsg] = React.useState(null)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const validation = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email format")
        .required("Please Enter Your Email"),
    }),
    onSubmit: async values => {
      try {
        const response = await axios.post(
          `${API_BASE_URL}/forgetPassword`,
          { email: values.email },
        )
        setForgetSuccessMsg(
          response.data.message || "Password reset email sent.",
        )

        setForgetError(null)
      } catch (error) {
        setForgetError(
          error.response?.data?.message ||
            "Something went wrong. Please try again.",
        )
        setForgetSuccessMsg(null)
      } finally {
        setLoading(false) // Hide spinner after request
      }
    },
  })

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-4">
                    <Link to="/" className="d-block auth-logo">
                      <img
                        src={logoDark}
                        alt=""
                        height="70"
                        className="auth-logo-dark"
                      />
                      <img
                        src={logoLight}
                        alt=""
                        height="70"
                        className="auth-logo-light"
                      />
                    </Link>
                  </h3>
                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-3 text-center">
                      Reset Password
                    </h4>
                    {forgetError && (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    )}
                    {forgetSuccessMsg && (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    )}
                    <form
                      className="form-horizontal mt-4"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <div className="mb-3">
                        <Label htmlFor="email">Email</Label>
                        <Input
                          name="email"
                          type="email"
                          placeholder="Enter Email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email &&
                          validation.errors.email && (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          )}
                      </div>

                      <Row className="mb-3">
                        <div className="col-12 text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            Reset
                          </button>
                        </div>
                      </Row>
                    </form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Remember It ?{" "}
                  <Link to="/login" className="text-primary">
                    {" "}
                    {loading ? (
                      <>
                        <Spinner size="sm" className="me-2" />
                        Sign In Here
                      </>
                    ) : (
                      " Sign In Here"
                    )}
                  </Link>{" "}
                </p>
                {/* © {new Date().getFullYear()} Lexa{" "}
                <span className="d-none d-sm-inline-block">
                  {" "}
                  - Crafted with <i className="mdi mdi-heart text-danger"></i>{" "}
                  by Themesbrand.
                </span> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
}

export default ForgetPasswordPage
