import React, { useState, useEffect } from "react"
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"

const FAQAccordion = props => {
  document.title = "FAQ | PostReview"

  const breadcrumbItems = [
    { title: "PostReview", link: "#" },
    { title: "FAQ", link: "/FAQ" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("FAQ", breadcrumbItems)
  }, [props])

  const [open, setOpen] = useState([])
  const [searchTerm, setSearchTerm] = useState("")

  const toggle = id => {
    if (open.includes(id)) {
      setOpen(open.filter(item => item !== id))
    } else {
      setOpen([...open, id])
    }
  }

  const questions = [
    {
      id: "1",
      question: "What is Postaireview?",
      answer:
        "Postaireview is an AI-generated review platform designed to help merchants manage and improve their Google reviews. It provides a system to handle negative reviews internally rather than having them posted on Google.",
    },
    {
      id: "2",
      question: "Who is Postaireview for?",
      answer:
        "Postaireview is for any merchant in any domain who wants to manage and improve their online reputation. Whether you run a small business, an e-commerce store, or a large organization, Postaireview helps handle reviews efficiently, improve Google ratings, and address negative feedback privately through its internal system.",
    },
    {
      id: "3",
      question: "How does Postaireview help increase my Google review rating?",
      answer:
        "Postaireview uses AI to generate professional, engaging, and personalized responses to customer reviews, encouraging better engagement and a positive brand image. This approach often results in higher ratings over time as businesses interact with their customers effectively.",
    },
    {
      id: "4",
      question: "How does Postaireview benefit my business?",
      answer:
        "With Postaireview, you can: Improve your Google review rating, handle negative feedback privately, and build stronger relationships with customers by addressing their concerns effectively.",
    },
    {
      id: "5",
      question: "How does the internal system for negative reviews work?",
      answer:
        "Negative reviews are captured and displayed only on the Postaireview internal Negative Review Form, keeping them away from Google. This allows businesses to review and address customer complaints privately, improving customer satisfaction without impacting their public Google rating.",
    },
    {
      id: "6",
      question: "How can I get my Google review URL?",
      answer:
        "Follow these steps to get your Google review URL:\n1. Log in to your Google account using Google Chrome.\n2. In the Google search bar, type 'My Business' and press Enter.\n3. Locate your business in the search results and click on the View Profile button.\n4. On your business profile page, look for the Ask for Reviews option and click on it.\n5. Google will display your review link and provide an option to copy it.\n6. Copy the review link and paste it into the Merchant Info form.",
    },
    {
      id: "7",
      question: "How can I get a QR code scanner?",
      answer:
        "Complete all the steps mentioned on the Dashboard. Afterward, purchase a subscription plan from the Subscription Page. Once the plan is active, Post Review will generate a QR code scanner for your business.",
    },
    {
      id: "8",
      question: "What kind of dashboard does Postaireview provide?",
      answer:
        "Postaireview offers an intuitive dashboard that displays both:\n- Positive reviews (to track customer satisfaction)\n- Negative reviews (to manage and resolve issues internally)\n- Scanner Image (How your scanner will look like)",
    },
    {
      id: "9",
      question: "What types of AI-generated responses are available?",
      answer:
        "Postaireview’s AI generates professional and personalized reviews for a variety of customer review types, including:\n- Positive reviews\n- Neutral reviews\n- Negative reviews",
    },
    {
      id: "10",
      question:
        "Can Postaireview prevent customers from posting negative reviews on Google?",
      answer:
        "Postaireview doesn’t directly stop customers from posting reviews on Google. However, it provides an alternative mechanism where merchants can encourage customers to share feedback on the internal system (Negative Review Form), minimizing the chances of negative feedback appearing on Google.",
    },
    {
      id: "11",
      question: "How is my payment processed?",
      answer:
        "Payments are securely processed through Razorpay, ensuring the highest level of data encryption and protection.",
    },
    {
      id: "12",
      question: "What happens if my payment fails?",
      answer:
        "If your payment fails:\n- Ensure your payment method is valid and has sufficient funds.\n- Retry the payment or use an alternative method.\n- Contact our support team if the issue persists.",
    },
    {
      id: "13",
      question: "How can I contact Postaireview support?",
      answer:
        "For assistance, reach out to us at support@postaireview.com. Our team is available Monday to Friday, 9:00 AM to 6:00 PM IST.",
    },
    {
      id: "14",
      question: "What if I face technical issues on the platform?",
      answer:
        "If you encounter technical issues:\n- Refresh your browser or clear your cache.\n- Check your internet connection.\n- Contact support with a description of the issue and a screenshot (if possible).",
    },
    {
      id: "15",
      question:
        "Can I send the review link to customers instead of using the scanner?",
      answer:
        "Yes, we generate personalized review links for each user, customized with their business names, which can be shared with customers.",
    },
    {
      id: "16",
      question: "How can I contact customers who left negative reviews?",
      answer:
        "Post Review captures all negative review data. Navigate to the Negative Reviews Page, where you'll find the customer’s name and mobile number. You can reach out to them directly and also record the resolution details by clicking the pencil icon for that customer.",
    },
    {
      id: "17",
      question: "How does negative review protection work?",
      answer:
        "Negative review protection is a feature available in the Merchant Info form. When enabled, customers giving a 3-star rating or lower are redirected to a feedback form instead of the Google review page. This helps collect detailed feedback while preventing low ratings from being published.",
    },
    {
      id: "18",
      question: "Can I turn off negative review protection?",
      answer:
        "Yes, you can disable negative review protection anytime by visiting the Merchant Info Page and toggling the Enable Negative Review Protection option off.",
    },
    {
      id: "19",
      question: "How can I request support?",
      answer:
        "Submit a support request by providing the necessary details on our Support Page. Our team will assist you as quickly as possible.",
    },
    {
      id: "20",
      question: "Can I cancel my subscription?",
      answer:
        "No, subscriptions are non-cancellable, and we do not offer refunds for purchased plans.",
    },
  ]

  const filteredQuestions = questions.filter(q =>
    q.question.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  return (
    <div className="faq-accordion">
      <div className="d-flex justify-content-end mb-3">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          className="form-control search-input"
          style={{ width: "500px" }}
        />
      </div>
      <Accordion open={open} toggle={toggle}>
        {filteredQuestions.map(item => (
          <AccordionItem key={item.id}>
            <AccordionHeader targetId={item.id}>
              {item.question}
            </AccordionHeader>
            <AccordionBody accordionId={item.id}>
              {item.answer.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </AccordionBody>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  )
}

export default connect(null, { setBreadcrumbItems })(FAQAccordion)
