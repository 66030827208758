// import React, { useState, useEffect } from "react"
// import { Formik, Form } from "formik"
// import {
//   Button,
//   Label,
//   Input,
//   FormFeedback,
//   Row,
//   Col,
//   Alert,
//   Spinner,
// } from "reactstrap"
// import CommonLogic from "../../components/Common/CommonLogic"
// import * as Yup from "yup"
// import axios from "axios"
// import { connect } from "react-redux"
// import { setBreadcrumbItems } from "store/actions"
// // Validation schema using Yup
// const validationSchema = Yup.object({
//   question1: Yup.string().required("Question 1 is required"),
//   keyword1: Yup.array().min(1, "At least one keyword is required"),
//   question2: Yup.string().required("Question 2 is required"),
//   keyword2: Yup.array().min(1, "At least one keyword is required"),
// })

// const AIForm = props => {
//   document.title = "AIForm | PostReview"

//   const breadcrumbItems = [
//     { title: "PostReview", link: "#" },

//     { title: "AI-Form", link: "/ai-form" },
//   ]
//   const [initialValues, setInitialValues] = useState({
//     question1: "",
//     keyword1: [],
//     question2: "",
//     keyword2: [],
//   })
//   const [loadingForm, setLoadingForm] = useState(true)
//   const [submitMessage, setSubmitMessage] = useState("")
//   const [error, setError] = useState("")
//   const [merchantId, setMerchantId] = useState(null)

//   // Fetch form data from API
//   useEffect(() => {
//     props.setBreadcrumbItems("AI-Form", breadcrumbItems)
//     if (merchantId !== null) {
//       fetchFormData()
//     }
//   }, [merchantId])
//   const fetchFormData = async () => {
//     if (!merchantId) return

//     try {
//       const response = await axios.get(
//         `https://api.postaireview.com/questionForms/${merchantId}`,
//         {
//           headers: {
//             Authorization: `Bearer ${localStorage.getItem("token")}`,
//           },
//         },
//       )

//       if (response.data) {
//         setInitialValues({
//           question1: response.data.question1 || "",
//           keyword1: response.data.question1Keywords || [],
//           question2: response.data.question2 || "",
//           keyword2: response.data.question2Keywords || [],
//         })
//       }
//     } catch (err) {
//       console.error("Error fetching form data:", err)
//       setError("Failed to fetch form data. Please try again.")
//     } finally {
//       setLoadingForm(false)
//     }
//   }
//   const handleSubmit = async (values, merchantData, token, resetForm) => {
//     if (!merchantData || !merchantData._id) {
//       setSubmitMessage("Merchant data not loaded. Please try again.")
//       return
//     }

//     try {
//       const payload = {
//         merchantInfoId: merchantData._id,
//         question1: values.question1,
//         question1Keywords: values.keyword1,
//         question2: values.question2,
//         question2Keywords: values.keyword2,
//       }

//       await axios.post("https://api.postaireview.com/questionForms", payload, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//           "Content-Type": "application/json",
//         },
//       })

//       setSubmitMessage("Data submitted successfully!")
//       resetForm()
//     } catch (error) {
//       console.error("Error submitting form data:", error)
//       setSubmitMessage("Failed to submit data.")
//     }
//   }

//   return (
//     <CommonLogic>
//       {({ merchantData, loading, error: merchantError }) => {
//         if (loading)
//           return (
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 height: "50vh",
//               }}
//             >
//               <Spinner
//                 size="xg"
//                 className="me-2"
//                 style={{ color: "#2B3A4A" }}
//               />
//               Loading...
//             </div>
//           )
//         if (merchantError) return <Alert color="danger">{merchantError}</Alert>
//         if (!merchantData || !merchantData._id)
//           return <Alert color="warning">Merchant data is not available.</Alert>

//         // Set the merchant ID once it's available
//         if (merchantData._id !== merchantId) {
//           setMerchantId(merchantData._id)
//         }

//         return (
//           <div>
//             {loadingForm ? (
//               <div>Loading form data...</div>
//             ) : (
//               <Formik
//                 initialValues={initialValues}
//                 validationSchema={validationSchema}
//                 onSubmit={(values, { resetForm }) =>
//                   handleSubmit(
//                     values,
//                     merchantData,
//                     localStorage.getItem("token"),
//                     resetForm,
//                   )
//                 }
//                 enableReinitialize
//               >
//                 {({
//                   values,
//                   errors,
//                   touched,
//                   handleChange,
//                   handleBlur,
//                   setFieldValue,
//                   setFieldTouched,
//                   isSubmitting,
//                 }) => (
//                   <Form
//                     style={{
//                       backgroundColor: "white",
//                       padding: "20px",
//                       borderRadius: "5px",
//                     }}
//                   >
//                     <Row>
//                       <Col md={8}>
//                         {submitMessage && (
//                           <Alert
//                             color={
//                               submitMessage === "Data submitted successfully!"
//                                 ? "success"
//                                 : "danger"
//                             }
//                           >
//                             {submitMessage}
//                           </Alert>
//                         )}
//                         {/* Question 1 */}
//                         <div className="mb-3">
//                           <Label htmlFor="question1">
//                             Enter Your First Question.
//                           </Label>
//                           <Input
//                             type="text"
//                             name="question1"
//                             id="question1"
//                             placeholder="Enter your first question"
//                             value={values.question1}
//                             onChange={handleChange}
//                             onBlur={handleBlur}
//                             invalid={touched.question1 && !!errors.question1}
//                           />
//                           {touched.question1 && errors.question1 && (
//                             <FormFeedback>{errors.question1}</FormFeedback>
//                           )}
//                         </div>

//                         {/* Keywords for Question 1 */}
//                         <div className="mb-3">
//                           <Label htmlFor="keyword1">
//                             Keywords for First Question.
//                           </Label>
//                           <small className="text-muted d-block mb-1">
//                             Separate each keyword using a comma or press Enter
//                           </small>
//                           <div
//                             className="token-input-container"
//                             style={{ display: "flex", flexWrap: "wrap" }}
//                           >
//                             {Array.isArray(values.keyword1) &&
//                               values.keyword1.map((keyword, index) => (
//                                 <div
//                                   key={index}
//                                   className="token"
//                                   style={{
//                                     backgroundColor: "#C7C8CC",
//                                     padding: "4px 8px",
//                                     borderRadius: "4px",
//                                     margin: "2px",
//                                     display: "inline-flex",
//                                     alignItems: "center",
//                                   }}
//                                 >
//                                   {keyword}
//                                   <span
//                                     className="token-remove"
//                                     style={{
//                                       marginLeft: "8px",
//                                       cursor: "pointer",
//                                       color: "black",
//                                     }}
//                                     onClick={() =>
//                                       setFieldValue(
//                                         "keyword1",
//                                         values.keyword1.filter(
//                                           k => k !== keyword,
//                                         ),
//                                       )
//                                     }
//                                   >
//                                     &times;
//                                   </span>
//                                 </div>
//                               ))}
//                             <Input
//                               type="text"
//                               placeholder="Enter keywords"
//                               onKeyDown={e => {
//                                 const newKeyword = e.target.value.trim()
//                                 if (e.key === "Enter" || e.key === ",") {
//                                   e.preventDefault()
//                                   if (
//                                     newKeyword &&
//                                     !values.keyword1.includes(newKeyword)
//                                   ) {
//                                     setFieldValue("keyword1", [
//                                       ...values.keyword1,
//                                       newKeyword,
//                                     ])
//                                     e.target.value = ""
//                                   }
//                                 }
//                               }}
//                               onBlur={e => {
//                                 const newKeyword = e.target.value.trim()
//                                 if (
//                                   newKeyword &&
//                                   !values.keyword1.includes(newKeyword)
//                                 ) {
//                                   setFieldValue("keyword1", [
//                                     ...values.keyword1,
//                                     newKeyword,
//                                   ])
//                                   e.target.value = ""
//                                 }
//                                 setFieldTouched("keyword1", true)
//                                 handleBlur(e)
//                               }}
//                               className={`token-input-field ${
//                                 touched.keyword1 && errors.keyword1
//                                   ? "is-invalid"
//                                   : ""
//                               }`}
//                             />
//                           </div>
//                           {touched.keyword1 && errors.keyword1 && (
//                             <FormFeedback style={{ display: "block" }}>
//                               {errors.keyword1}
//                             </FormFeedback>
//                           )}
//                         </div>
//                         {/* Question 2 */}
//                         <div className="mb-3">
//                           <Label htmlFor="question2">
//                             Enter Your Second Question.
//                           </Label>
//                           <Input
//                             type="text"
//                             name="question2"
//                             id="question2"
//                             placeholder="Enter your second question"
//                             value={values.question2}
//                             onChange={handleChange}
//                             onBlur={handleBlur}
//                             invalid={touched.question2 && !!errors.question2}
//                           />
//                           {touched.question2 && errors.question2 && (
//                             <FormFeedback>{errors.question2}</FormFeedback>
//                           )}
//                         </div>

//                         {/* Keywords for Question 2 */}
//                         <div className="mb-3">
//                           <Label htmlFor="keyword2">
//                             Keywords for Second Question.
//                           </Label>
//                           <small className="text-muted d-block mb-1">
//                             Separate each keyword using a comma or press Enter
//                           </small>
//                           <div
//                             className="token-input-container"
//                             style={{ display: "flex", flexWrap: "wrap" }}
//                           >
//                             {Array.isArray(values.keyword2) &&
//                               values.keyword2.map((keyword, index) => (
//                                 <div
//                                   key={index}
//                                   className="token"
//                                   style={{
//                                     backgroundColor: "#C7C8CC",
//                                     padding: "4px 8px",
//                                     borderRadius: "4px",
//                                     margin: "2px",
//                                     display: "inline-flex",
//                                     alignItems: "center",
//                                   }}
//                                 >
//                                   {keyword}
//                                   <span
//                                     className="token-remove"
//                                     style={{
//                                       marginLeft: "8px",
//                                       cursor: "pointer",
//                                       color: "black",
//                                     }}
//                                     onClick={() =>
//                                       setFieldValue(
//                                         "keyword2",
//                                         values.keyword2.filter(
//                                           k => k !== keyword,
//                                         ),
//                                       )
//                                     }
//                                   >
//                                     &times;
//                                   </span>
//                                 </div>
//                               ))}
//                             <Input
//                               type="text"
//                               placeholder="Enter keywords"
//                               onKeyDown={e => {
//                                 const newKeyword = e.target.value.trim()
//                                 if (e.key === "Enter" || e.key === ",") {
//                                   e.preventDefault()
//                                   if (
//                                     newKeyword &&
//                                     !values.keyword2.includes(newKeyword)
//                                   ) {
//                                     setFieldValue("keyword2", [
//                                       ...values.keyword2,
//                                       newKeyword,
//                                     ])
//                                     e.target.value = ""
//                                   }
//                                 }
//                               }}
//                               onBlur={e => {
//                                 const newKeyword = e.target.value.trim()
//                                 if (
//                                   newKeyword &&
//                                   !values.keyword2.includes(newKeyword)
//                                 ) {
//                                   setFieldValue("keyword2", [
//                                     ...values.keyword2,
//                                     newKeyword,
//                                   ])
//                                   e.target.value = ""
//                                 }
//                                 setFieldTouched("keyword2", true)
//                                 handleBlur(e)
//                               }}
//                               className={`token-input-field ${
//                                 touched.keyword2 && errors.keyword2
//                                   ? "is-invalid"
//                                   : ""
//                               }`}
//                             />
//                           </div>
//                           {touched.keyword2 && errors.keyword2 && (
//                             <FormFeedback style={{ display: "block" }}>
//                               {errors.keyword2}
//                             </FormFeedback>
//                           )}
//                         </div>
//                       </Col>
//                     </Row>
//                     {/* Submit Button */}
//                     <Button
//                       color="primary"
//                       type="submit"
//                       className="mt-3"
//                       disabled={isSubmitting}
//                     >
//                       {isSubmitting ? (
//                         <>
//                           <Spinner size="sm" className="me-2" />
//                           submitting...
//                         </>
//                       ) : (
//                         "Submit"
//                       )}
//                     </Button>
//                   </Form>
//                 )}
//               </Formik>
//             )}
//           </div>
//         )
//       }}
//     </CommonLogic>
//   )
// }

// export default connect(null, { setBreadcrumbItems })(AIForm)

import React, { useState, useEffect } from "react"
import { Formik, Form } from "formik"
import {
  Button,
  Label,
  Input,
  FormFeedback,
  Row,
  Col,
  Alert,
  Spinner,
} from "reactstrap"
import CommonLogic from "../../components/Common/CommonLogic"
import * as Yup from "yup"
import axios from "axios"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "store/actions"

// Validation schema using Yup
const validationSchema = Yup.object({
  question1: Yup.string().required("Question 1 is required"),
  keyword1: Yup.array().min(1, "At least one keyword is required"),
  question2: Yup.string().required("Question 2 is required"),
  keyword2: Yup.array().min(1, "At least one keyword is required"),
})

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const AIForm = props => {
  document.title = "AIForm | PostReview"

  const breadcrumbItems = [
    { title: "PostReview", link: "#" },
    { title: "AI-Form", link: "/ai-form" },
  ]

  const [initialValues, setInitialValues] = useState({
    question1: "",
    keyword1: [],
    question2: "",
    keyword2: [],
  })
  const [loadingForm, setLoadingForm] = useState(true)
  const [submitMessage, setSubmitMessage] = useState("")
  const [error, setError] = useState("")
  const [merchantId, setMerchantId] = useState(null)

  // Fetch form data from API
  useEffect(() => {
    props.setBreadcrumbItems("AI-Form", breadcrumbItems)
    if (merchantId !== null) {
      fetchFormData()
    }
  }, [merchantId])

  const fetchFormData = async () => {
    if (!merchantId) return

    try {
      const response = await axios.get(
        `${API_BASE_URL}/questionForms/${merchantId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      )

      if (response.data) {
        setInitialValues({
          question1: response.data.question1 || "",
          keyword1: response.data.question1Keywords || [],
          question2: response.data.question2 || "",
          keyword2: response.data.question2Keywords || [],
        })
      }
    } catch (err) {
      console.error("Error fetching form data:", err)
      setError("Failed to fetch form data. Please try again.")
    } finally {
      setLoadingForm(false)
    }
  }

  const handleSubmit = async (values, merchantData, token, resetForm) => {
    if (!merchantData || !merchantData._id) {
      setSubmitMessage("Merchant data not loaded. Please try again.")
      return
    }

    const payload = {
      merchantInfoId: merchantData._id,
      question1: values.question1,
      question1Keywords: values.keyword1,
      question2: values.question2,
      question2Keywords: values.keyword2,
    }

    // Determine whether to use PUT or POST
    const apiUrl = `${API_BASE_URL}/questionForms/${merchantData._id}`
    let apiMethod = "PUT"

    try {
      await axios({
        method: apiMethod,
        url: apiUrl,
        data: payload,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })

      setSubmitMessage(
        apiMethod === "PUT"
          ? "Form updated successfully!"
          : "Form created successfully!",
      )
      fetchFormData() // Refresh the form data
    } catch (error) {
      console.error("Error submitting form data:", error)

      if (error.response && error.response.status === 404) {
        console.log("Form not found, creating a new one...")
        try {
          await axios.post(
            `${API_BASE_URL}/questionForms`,
            payload,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            },
          )

          setSubmitMessage("Form created successfully!")
          fetchFormData() // Refresh the form data
        } catch (postError) {
          console.error("Error creating form:", postError)
          setSubmitMessage("Failed to create form. Please try again.")
        }
      } else {
        setSubmitMessage("Failed to submit or update form. Please try again.")
      }
    }
  }

  return (
    <CommonLogic>
      {({ merchantData, loading, error: merchantError }) => {
        if (loading)
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <Spinner
                size="xg"
                className="me-2"
                style={{ color: "#2B3A4A" }}
              />
              Loading...
            </div>
          )
        if (merchantError) return <Alert color="danger">{merchantError}</Alert>
        if (!merchantData || !merchantData._id)
          return <Alert color="warning">Merchant data is not available.</Alert>

        // Set the merchant ID once it's available
        if (merchantData._id !== merchantId) {
          setMerchantId(merchantData._id)
        }

        return (
          <div>
            {loadingForm ? (
              <div>Loading form data...</div>
            ) : (
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }) =>
                  handleSubmit(
                    values,
                    merchantData,
                    localStorage.getItem("token"),
                    resetForm,
                  )
                }
                enableReinitialize
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }) => (
                  <Form
                    style={{
                      backgroundColor: "white",
                      padding: "20px",
                      borderRadius: "5px",
                    }}
                  >
                    <Row>
                      <Col md={8}>
                        {submitMessage && (
                          <Alert
                            color={
                              submitMessage.includes("successfully")
                                ? "success"
                                : "danger"
                            }
                          >
                            {submitMessage}
                          </Alert>
                        )}
                        {/* Question 1 */}
                        <div className="mb-3">
                          <Label htmlFor="question1">
                            Enter Your First Question.
                          </Label>
                          <Input
                            type="text"
                            name="question1"
                            id="question1"
                            placeholder="Enter your first question"
                            value={values.question1}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            invalid={touched.question1 && !!errors.question1}
                          />
                          {touched.question1 && errors.question1 && (
                            <FormFeedback>{errors.question1}</FormFeedback>
                          )}
                        </div>
                        {/* Keywords for Question 1 */}
                        <div className="mb-3">
                          <Label htmlFor="keyword1">
                            Keywords for First Question.
                          </Label>
                          <small className="text-muted d-block mb-1">
                            Separate each keyword using a comma or press Enter
                          </small>
                          <div className="token-input-container">
                            {Array.isArray(values.keyword1) &&
                              values.keyword1.map((keyword, index) => (
                                <div
                                  key={index}
                                  className="token"
                                  style={{
                                    backgroundColor: "#C7C8CC",
                                    padding: "4px 8px",
                                    borderRadius: "4px",
                                    margin: "2px",
                                    display: "inline-flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {keyword}
                                  <span
                                    className="token-remove"
                                    style={{
                                      marginLeft: "8px",
                                      cursor: "pointer",
                                      color: "black",
                                    }}
                                    onClick={() =>
                                      setFieldValue(
                                        "keyword1",
                                        values.keyword1.filter(
                                          k => k !== keyword,
                                        ),
                                      )
                                    }
                                  >
                                    &times;
                                  </span>
                                </div>
                              ))}
                            <Input
                              type="text"
                              placeholder="Enter keywords"
                              onKeyDown={e => {
                                const newKeyword = e.target.value.trim()
                                if (e.key === "Enter" || e.key === ",") {
                                  e.preventDefault()
                                  if (
                                    newKeyword &&
                                    !values.keyword1.includes(newKeyword)
                                  ) {
                                    setFieldValue("keyword1", [
                                      ...values.keyword1,
                                      newKeyword,
                                    ])
                                    e.target.value = ""
                                  }
                                }
                              }}
                              onBlur={e => {
                                const newKeyword = e.target.value.trim()
                                if (
                                  newKeyword &&
                                  !values.keyword1.includes(newKeyword)
                                ) {
                                  setFieldValue("keyword1", [
                                    ...values.keyword1,
                                    newKeyword,
                                  ])
                                  e.target.value = ""
                                }
                                setFieldTouched("keyword1", true)
                                handleBlur(e)
                              }}
                              className={`token-input-field ${
                                touched.keyword1 && errors.keyword1
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                          </div>
                          {touched.keyword1 && errors.keyword1 && (
                            <FormFeedback style={{ display: "block" }}>
                              {errors.keyword1}
                            </FormFeedback>
                          )}
                        </div>
                        {/* Question 2 */}
                        <div className="mb-3">
                          <Label htmlFor="question2">
                            Enter Your Second Question.
                          </Label>
                          <Input
                            type="text"
                            name="question2"
                            id="question2"
                            placeholder="Enter your Second question"
                            value={values.question2}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            invalid={touched.question2 && !!errors.question2}
                          />
                          {touched.question2 && errors.question2 && (
                            <FormFeedback>{errors.question2}</FormFeedback>
                          )}
                        </div>
                        {/* Keywords for Question 2 */}
                        <div className="mb-3">
                          <Label htmlFor="keyword2">
                            Keywords for Second Question.
                          </Label>
                          <small className="text-muted d-block mb-1">
                            Separate each keyword using a comma or press Enter
                          </small>
                          <div className="token-input-container">
                            {Array.isArray(values.keyword2) &&
                              values.keyword2.map((keyword, index) => (
                                <div
                                  key={index}
                                  className="token"
                                  style={{
                                    backgroundColor: "#C7C8CC",
                                    padding: "4px 8px",
                                    borderRadius: "4px",
                                    margin: "2px",
                                    display: "inline-flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {keyword}
                                  <span
                                    className="token-remove"
                                    style={{
                                      marginLeft: "8px",
                                      cursor: "pointer",
                                      color: "black",
                                    }}
                                    onClick={() =>
                                      setFieldValue(
                                        "keyword2",
                                        values.keyword2.filter(
                                          k => k !== keyword,
                                        ),
                                      )
                                    }
                                  >
                                    &times;
                                  </span>
                                </div>
                              ))}
                            <Input
                              type="text"
                              placeholder="Enter keywords"
                              onKeyDown={e => {
                                const newKeyword = e.target.value.trim()
                                if (e.key === "Enter" || e.key === ",") {
                                  e.preventDefault()
                                  if (
                                    newKeyword &&
                                    !values.keyword2.includes(newKeyword)
                                  ) {
                                    setFieldValue("keyword2", [
                                      ...values.keyword2,
                                      newKeyword,
                                    ])
                                    e.target.value = ""
                                  }
                                }
                              }}
                              onBlur={e => {
                                const newKeyword = e.target.value.trim()
                                if (
                                  newKeyword &&
                                  !values.keyword1.includes(newKeyword)
                                ) {
                                  setFieldValue("keyword2", [
                                    ...values.keyword2,
                                    newKeyword,
                                  ])
                                  e.target.value = ""
                                }
                                setFieldTouched("keyword2", true)
                                handleBlur(e)
                              }}
                              className={`token-input-field ${
                                touched.keyword2 && errors.keyword2
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                          </div>
                          {touched.keyword2 && errors.keyword2 && (
                            <FormFeedback style={{ display: "block" }}>
                              {errors.keyword2}
                            </FormFeedback>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Button
                      color="primary"
                      type="submit"
                      className="mt-3"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <>
                          <Spinner size="sm" className="me-2" />
                          submitting...
                        </>
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        )
      }}
    </CommonLogic>
  )
}

export default connect(null, { setBreadcrumbItems })(AIForm)
