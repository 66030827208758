import React, { useEffect, useState } from "react"
import { Button, Col, Row, Card, CardBody } from "reactstrap"
import { useLocation } from "react-router-dom"
import axios from "axios"

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const ConfirmMsg = () => {
  const [loadingForm, setLoadingForm] = useState(false)
  const [merchantData, setMerchantData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [logoUrl, setLogoUrl] = useState("")
  const location = useLocation()
  const { businessname } = location.state || {}
  console.log("merchantData", merchantData)
  console.log("businessname", businessname)
  console.log("logoUrl", logoUrl)
  useEffect(() => {
    const fetchMerchantData = async () => {
      try {
        if (!businessname) {
          setError("businessname is required to fetch merchant data")
          setLoading(false)
          return
        }

        const response = await axios.get(
          `${API_BASE_URL}/merchantsinfos/${businessname}`,
          // {
          //   headers: {
          //     Authorization: `Bearer ${token}`,
          //     "Content-Type": "application/json",
          //   },
          // },
        )

        if (response && response.data) {
          console.log("abc", response.data)

          setMerchantData(response.data)
          // Handle logo setting directly after data fetch
          const logo = response.data?.logo

          if (typeof logo === "string") {
            // Directly set the logo if it's a URL string
            setLogoUrl(logo)
          } else if (logo?.data) {
            // Convert buffer to Blob URL if necessary
            const convertBufferToBlobUrl = bufferData => {
              const blob = new Blob([new Uint8Array(bufferData)], {
                type: "image/jpeg", // Adjust MIME type as needed
              })
              return URL.createObjectURL(blob)
            }

            const generatedLogoUrl = convertBufferToBlobUrl(logo.data)
            setLogoUrl(generatedLogoUrl)
          } else {
            setLogoUrl(null)
          }
        } else {
          setError("No merchant data found for the given email")
        }
      } catch (err) {
        console.error("Error fetching merchant data:", err)
        setError("Failed to fetch merchant data")
      } finally {
        setLoading(false)
      }
    }

    fetchMerchantData()
  }, [businessname])
  return (
    <Row
      className="justify-content-center align-items-center"
      style={{ minHeight: "100vh", backgroundColor: "#f8f9fa" }}
    >
      <Col xl="6" lg="8" md="10" sm="12" xs="12">
        <Card className="shadow-lg rounded">
          <CardBody className="p-5 text-center">
            <Row className="text-center">
              <Col>
                {logoUrl ? (
                  <img
                    src={logoUrl}
                    // src={dummyQRCode}
                    alt="Logo"
                    className="img-fluid"
                    style={{ height: "140px" }}
                  />
                ) : (
                  <p>Logo not available...</p>
                )}
              </Col>
            </Row>

            <p
              style={{
                fontSize: "30px",
                fontWeight: "bold",
                color: "#7A6FBE",
              }}
            >
              Thank You for Your Review!
            </p>
            <p
              style={{
                color: "black",
                fontSize: "18px",
              }}
              className="mb-4"
            >
              Your response has been successfully submitted. We are working on
              resolving the issue as soon as possible.
            </p>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default ConfirmMsg
