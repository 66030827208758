// import React, { useEffect, useState } from "react"
// import { Navigate } from "react-router-dom"
// import axios from "axios"

// const Authmiddleware = props => {
//   const [isAuthenticated, setIsAuthenticated] = useState(null) // State to track authentication
//   const token = localStorage.getItem("token")
//   console.log("token", token)
//   useEffect(() => {
//     const verifyToken = async () => {
//       try {
//         if (!token) {
//           console.error("Token is missing!")
//           setIsAuthenticated(false) // Redirect if token is not found
//           return
//         }

//         // API call to verify token with Bearer token in the Authorization header
//         const response = await axios.post(
//           "https://api.postaireview.com/verifytoken", // Ensure the endpoint is correct
//           {},
//           {
//             headers: {
//               Authorization: `Bearer ${token}`, // Pass token in header
//               "Content-Type": "application/json",
//             },
//           },
//         )

//         if (response.status === 200) {
//           console.log("Token verified successfully:", response.data)
//           setIsAuthenticated(true) // Allow access
//         } else {
//           console.error(
//             "Token verification failed with status:",
//             response.status,
//           )
//           setIsAuthenticated(false) // Redirect on failure
//         }
//       } catch (error) {
//         console.error("Token verification failed:", error.message)
//         console.error("Error response data:", error.response?.data)
//         setIsAuthenticated(false) // Redirect on error
//       }
//     }

//     verifyToken()
//   }, [token])

//   // Show a loading message while authentication is being verified
//   if (isAuthenticated === null) {
//     return <div>Loading...</div>
//   }

//   // Redirect to login if authentication fails
//   if (!isAuthenticated) {
//     return <Navigate to="/login" replace />
//   }

//   // Render child components if authentication succeeds
//   return <React.Fragment>{props.children}</React.Fragment>
// }

// export default Authmiddleware

import React, { useEffect, useState } from "react"
import { Navigate } from "react-router-dom"
import axios from "axios"

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Authmiddleware = props => {
  const [isAuthenticated, setIsAuthenticated] = useState(null) // Track authentication state
  const token = localStorage.getItem("token") // Retrieve token from localStorage

  useEffect(() => {
    const verifyToken = async () => {
      try {
        if (!token) {
          console.error("Token is missing!")
          setIsAuthenticated(false) // Redirect if token is missing
          return
        }

        // Verify token via API
        const response = await axios.post(
          `${API_BASE_URL}/verifytoken`,
          {}, // Pass an empty body
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include token in Authorization header
              "Content-Type": "application/json",
            },
          },
        )

        if (response.status === 200 && response.data.message === "Success") {
          console.log("Token verified successfully:", response.data)
          setIsAuthenticated(true) // Grant access if token is valid
        } else {
          console.error(
            "Unexpected response from token verification:",
            response.data,
          )
          setIsAuthenticated(false) // Redirect if verification fails
        }
      } catch (error) {
        console.error("Token verification failed:", error.message)
        console.error("Error details:", error.response?.data || error.message)
        setIsAuthenticated(false) // Redirect on error
      }
    }

    verifyToken()
  }, [token])

  // Display a loading message while verification is in progress
  if (isAuthenticated === null) {
    return <div>Loading...</div>
  }

  // Redirect to login if not authenticated
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />
  }

  // Render child components if authenticated
  return <React.Fragment>{props.children}</React.Fragment>
}

export default Authmiddleware
