import React, { useState } from "react"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

import logoLight from "../../assets/images/Logo.png"
import { Link, useNavigate } from "react-router-dom"
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap"

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Register = () => {
  document.title = "Register | PostReview"
  const [apiError, setApiError] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email")
        .required("Please Enter Your Email"),
    }),
    onSubmit: async values => {
      setLoading(true) // Set loading to true when submitting
      try {
        const response = await fetch(
          `${API_BASE_URL}/registerUser`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
          },
        )

        if (response.ok) {
          const result = await response.json()
          setSuccessMessage(
            "Registration successful! An activation link has been sent to your email. Please check your inbox and activate your account.",
          )
        } else {
          const error = await response.json()
          setApiError(error.message || "Registration failed")
        }
      } catch (error) {
        setApiError("An error occurred. Please try again.")
      } finally {
        setLoading(false) // Hide spinner after request
      }
    },
  })

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-4">
                    <Link to="/" className="d-block auth-logo">
                      <img
                        src={logoLight}
                        alt=""
                        height="70"
                        className="auth-logo-dark"
                      />
                      <img
                        src={logoLight}
                        alt=""
                        height="70"
                        className="auth-logo-light"
                      />
                    </Link>
                  </h3>
                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-1 text-center">
                      Register
                    </h4>
                    <Form
                      className="form-horizontal mt-4"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {successMessage && (
                        <Alert color="success">{successMessage}</Alert>
                      )}
                      {apiError && <Alert color="danger">{apiError}</Alert>}

                      <div className="mb-3">
                        <Label htmlFor="email">Email</Label>
                        <Input
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                          disabled={loading} // Disable input when loading
                        />
                        {validation.touched.email &&
                          validation.errors.email && (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          )}
                      </div>

                      <div className="mb-3 row mt-4">
                        <div className="col-12 text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                            disabled={loading} // Disable button when loading
                          >
                            {loading ? (
                              <>
                                <Spinner size="sm" className="me-2" />
                                Register...
                              </>
                            ) : (
                              "Register"
                            )}
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account?{" "}
                  <Link to="/login" className="text-primary">
                    Login
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Register
