import React, { useState, useEffect } from "react"
import { Row, Col, Alert, Spinner } from "reactstrap"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import axios from "axios"
import CommonLogic from "./CommonLogic"

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const Breadcrumb = props => {
  const [reviewCount, setReviewCount] = useState("")
  const [loading, setLoading] = useState(true) // Loading state
  const [error, setError] = useState(null) // Error state
  const [merchantId, setMerchantId] = useState(null)

  const itemLength = (props.breadcrumbItems || []).length || 1
  const token = localStorage.getItem("token")
  useEffect(() => {
    if (merchantId !== null) {
      FetchAllCount()
    }
  }, [merchantId])
  const FetchAllCount = async () => {
    if (!merchantId) return
    try {
      const response = await axios.get(
        `${API_BASE_URL}/reviews/count/${merchantId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Pass token in header
            "Content-Type": "application/json",
          },
        },
      )

      if (response && response.data) {
        setReviewCount(response.data) // Set fetched data to state
      } else {
        setError("No data found")
      }
    } catch (error) {
      console.error("Error fetching reviews count:", error)
      setError("Failed to fetch reviews count")
    } finally {
      setLoading(false)
    }
  }

  return (
    <CommonLogic>
      {({ merchantData, error: merchantError }) => {
        // if (loading)
        //   return (
        //     <div
        //       style={{
        //         display: "flex",
        //         justifyContent: "center",
        //         alignItems: "center",
        //         height: "50vh",
        //       }}
        //     >
        //       <Spinner
        //         size="xg"
        //         className="me-2"
        //         style={{ color: "#2B3A4A" }}
        //       />
        //       Loading...
        //     </div>
        //   )
        // if (merchantError) return <Alert color="danger">{merchantError}</Alert>
        if (!merchantData || !merchantData?._id)
          return <Alert color="warning">Merchant data is not available.</Alert>

        // Set the merchant ID once it's available
        if (merchantData._id !== merchantId) {
          setMerchantId(merchantData._id)
        }

        return (
          <div>
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4>{props.title}</h4>
                  <ol className="breadcrumb m-0">
                    {(props.breadcrumbItems || []).map((item, key) =>
                      key + 1 === itemLength ? (
                        <li key={key} className="breadcrumb-item active">
                          {item.title}
                        </li>
                      ) : (
                        <li key={key} className="breadcrumb-item">
                          <Link to="#">{item.title}</Link>
                        </li>
                      ),
                    )}
                  </ol>
                </div>
              </Col>

              <Col sm={6}>
                <div className="state-information d-none d-sm-block">
                  <div className="state-graph">
                    <div className="info" style={{ fontSize: "16px" }}>
                      <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                        {reviewCount?.negativeReviews}
                      </span>{" "}
                      : Negative Review
                    </div>
                  </div>
                  <div className="state-graph">
                    <div className="info" style={{ fontSize: "16px" }}>
                      <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                        {reviewCount?.positiveReviews}
                      </span>{" "}
                      : Positive Review
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        )
      }}
    </CommonLogic>
  )
}

const mapStatetoProps = state => {
  const Layout = state.Layout
  const BreadcrumbData = state.Breadcrumb
  return {
    layoutType: Layout.layoutType,
    title: BreadcrumbData.title,
    breadcrumbItems: BreadcrumbData.breadcrumbItems,
  }
}

export default connect(mapStatetoProps, {})(Breadcrumb)
